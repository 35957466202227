import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import Par from "../../components/Par";

// Download link - Download GESA Information: Percutaneous Endoscopic Gastrostomy (PEG) - http://www.fbrennangastro.com.au/downloads/gesapeg.pdf

const title = "Percutaneous Endoscopic Gastrostomy (PEG)";
const Services = () => {
	return (
		<div>
			<SEO
				title={title}
				keywords={[
					title,
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description={title}
			/>
			<H1 text={title} />
			<Par>
				Percutaneous Endoscopic Gastrostomy (PEG) is an endoscopic medical procedure used when oral food intake is
				inadequate. PEG administration of enteral feeds is the most common method of nutritional support for patients in
				the community.
			</Par>
			<Par>
				PEG stands for Percutaneous (through the skin) Endoscopic (using anendoscopic instrument) Gastrostomy (to the
				stomach). The doctor creates a hole in the abdominal wall to allow a feeding tube to be inserted directly into
				the stomach.
			</Par>
			<Par>
				It is a simple and safe way of receiving food when there are problems with swallowing or eating, and is an
				alternative to surgical gastrostomy insertion.
			</Par>
			<Par>A PEG can be temporary or permanent and is used in both adults and children.</Par>
		</div>
	);
};

export default Services;
